<template>
  <div v-if="item">
    {{ $util.formatDate(item.updated, true) }}
    <span v-if="item.updatedById"> by
      <router-link v-if="resolvedUserName" :to="{ name: 'UserDetails', params: { Id: item.updatedById } }">
        {{ resolvedUserName }}
      </router-link>
      <span class="font-weight-bold" v-else>
        Unknown user
      </span>
    </span>
  </div>
</template>
<script>
import apiService from '@/services/apiService.js';

export default {
  name: 'last-updated-field',
  data: () => ({
    resolvedUser: null,
  }),
  computed: {
    resolvedUserName() {
      return this.resolvedUser?.email;
    }
  },
  props: {
    item: Object
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        this.fetchUser();
      }
    }
  },
  methods: {
    async fetchUser() {
      if (this.item?.updatedById) {
        try {
          this.resolvedUser = await apiService.getUserById(this.item.updatedById, { noErrorHandler: true });
        }
        catch {
          console.log('unable to resolve user');
        }
      }
    }
  }
}
</script>